import { type SerializeFrom } from '@remix-run/node'
import { useRouteLoaderData } from '@remix-run/react'
import { type loader as rootLoader } from '#app/root.tsx'
import { Keyword } from '@prisma/client';

function isUser(user: any): user is SerializeFrom<typeof rootLoader>['user'] {
	return user && typeof user === 'object' && typeof user.id === 'number'
}

export function useOptionalUser(): {
	isLoggedIn: false;
	librarySize: number;
} | {
	id: number;
	imageUrl: string | null;
	notificationCount: number;
	isLoggedIn: true;
	name: string | null;
	email: string;
	careerKeyword: SerializeFrom<Keyword | null>;
	followKeywords: SerializeFrom<{
    createdAt: Date;
    updatedAt: Date;
    userId: number;
    keywordId: number;
} & {
    keyword: Keyword;
}>[];
	librarySize: number;
} {
	const data = useRouteLoaderData<typeof rootLoader>('root')

	if (!data) {
		throw new Error('No data found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead.')
	}
	
	if(!isUser(data.user)) {
		return {
			isLoggedIn: false,
			librarySize: data.librarySize,
		}
	}
	
	return {
		id: data.user!.id,
		imageUrl: data.user!.imageUrl,
		isLoggedIn: true,
		email: data.user!.email,
		name: data.user!.name,
		notificationCount: data.user!.notificationCount,
		followKeywords: data.user!.followKeywords,
		librarySize: data.librarySize,
		careerKeyword: data.user!.careerKeyword,
	}
}

export function useUser() {
	const maybeUser = useOptionalUser()
	
	if (!maybeUser) {
		throw new Error(
			'No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead.',
		)
	}
	return maybeUser
}
